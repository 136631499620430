<template>
	<div class="text-center">
		<div class="jumbotron jumbotron-fluid pt-1 pb-0">
			<div class="row mr-0">
				<!-- left column -->
				<div class="col bg-purple">
					<div class="container has-width-50 float-right">
						<div class="row">
							<div class="col">
								<div class="valign-parent">
									<div class="valign-child">
										<h1 class="banner-text mt-2">ONTSPANNEN NAAR HET EINDEXAMEN</h1>
									</div>
									<div class="valign-child pl-2">
										<Arrow v-if="!showText" link="/ontspannen-naar-eindexamen"></Arrow>
									</div>
								</div>
								<div v-if="showText">
									<p
										class="banner-text pr-large"
									>Een online training voor eindexamen kandidaten</p>
									<div class="valign-parent">
										<div class="valign-child">
											<span class="banner-text">Bekijk de informatie over de training!</span>
										</div>
										<div class="valign-child">
											<Arrow link="/ontspannen-naar-eindexamen"></Arrow>
										</div>
									</div>
									<br />
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- right column -->
				<div class="col bg-orange">
					<div class="container has-width-50 float-md-left">
						<div class="row">
							<div class="col pl-large">
								<div class="valign-parent">
									<div class="valign-child">
										<h1 class="banner-text mt-2">ZELFKENNIS 10-14 JARIGEN</h1>
									</div>
									<div class="valign-child pl-2">
										<Arrow v-if="!showText" link="/lessenserie-po"></Arrow>
									</div>
								</div>
								<div v-if="showText">
									<p
										class="banner-text"
									>In het leven van een 10-14 jarige gebeurt veel. Een lichaam dat verandert, jezelf verhouden ten opzichte van de groep, de stap van de basisschool naar ‘de middelbare’, een nieuwe start in de brugklas. Laat leerlingen leren over zichzelf door lessen in zelfkennis te geven. Dat geeft rust en (zelf)vertrouwen in een turbulente levensfase.</p>
									<div class="valign-parent">
										<div class="valign-child">
											<span class="banner-text">Bekijk de lespakketten Zelfkennis</span>
										</div>
										<div class="valign-child">
											<Arrow link="/lessenserie-po"></Arrow>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- logo button in the middle -->
		<router-link to="/over-knapkind" class="d-none d-sm-none d-md-block">
			<img src="@/assets/logo-knop.png" class="logo-knop-small float-right" v-bind:class="{'logo-knop-large': showText}" />
		</router-link>
	</div>
</template>

<script>
import Arrow from '@/components/Arrow.vue';

export default {
	data() {
		return {
			styleObject: {
				top: '362px',
				marginLeft: '-50px',
				maxWidth: '86px'
			}
		};
	},
	components: {
		Arrow
	},
	props: {
		showText: Boolean
	}
};
</script>


<style scoped>
.banner-text {
	color: white;
	text-align: left;
	padding-right: 5px;
}

.logo-knop-small {
	position: absolute;
	top: 304px;
	margin-left: -50px;
	max-width: 86px;
	z-index: 9;
}

.logo-knop-large {
	position: absolute;
	top: 304px;
	margin-left: -117px;
	max-width: 220px;
	z-index: 9;
}

.has-width-50 {
	max-width: 570px !important;
}

@media (min-width: 768px) {
	.pr-large {
		padding-right: 90px;
	}
}

@media (min-width: 768px) {
	.pl-large {
		padding-left: 120px;
	}
}

.valign-parent {
	display: table;
}
.valign-child {
	display: table-cell;
	vertical-align: middle;
}
</style>
